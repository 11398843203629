import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const DataGrid = ({ rowData, columnDefs,frameworkComponent,gridRef,height }) => {
  return (
    <div className="ag-theme-quartz" style={{ height: height, width: '100%',fontFamily:'Inter' }}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        components={frameworkComponent}
        rowSelection={"multiple"}
      />
    </div>
  );
};

export default DataGrid;
