import React,{useState,useEffect} from 'react';
import { Grid, Button } from '@mui/joy';
import DataGrid from './components/DataGrid';
import axios from 'axios';
import Box from '@mui/material/Box';
import DeleteButtonCellRenderer from './cellRenderer/DeleteButton';

function SKUList() {

    const columnDefs = [
      {
        headerName: "Delete STN",
        cellRenderer: 'downloadButtonCellRenderer',
        cellRendererParams : {
          url : "https://stnapiv2.stcsolutions.shop/api/sku/delete/"
        },
        width: 150
      },
        { headerName: "Name", field: "name",filter:true,width : 550  },
        { headerName: "ID", field: "id", hide : true },
        { headerName: "MRP", field: "mrp",filter:true }
      ];

      const [rowData, setRowData] = useState([]);
      const getData  = async (apiURL) => {
        try {
            const response = await axios.get(apiURL);
            
            return response.data;
        } catch (error) {
            return error.message;
        } finally {
            
        }
    };

    useEffect(() => {
        const fetchData = async () => {
          const data = await getData("https://stnapiv2.stcsolutions.shop/api/SKU");
          setRowData(data);
        };
    
        fetchData();
      }, []); 
  return (
    <Box sx={{ margin: '16px' }}>
    <Grid item xs={12} p={4} sx={{width : '100%'}}>


          <DataGrid
            rowData={rowData}
            columnDefs={columnDefs}
                height={'calc(100vh - 180px)'}
                frameworkComponent={{
                    downloadButtonCellRenderer : DeleteButtonCellRenderer
                }}
          />




        </Grid>
        </Box>
  );
  
  
}




export default SKUList;
