import React,{useState} from 'react';
import STNSKUDetailModal from '../components/STNSKUDetailModal';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const LinkCellRenderer = (props) => {
    const { value, data } = props; // Extract value and other props as needed
    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const handleClick = () => {
        // Define your custom click logic here
        // For example, navigate to a URL, open a dialog, or make an API request
        openModal()
    };

    return (
        <>
       
      <Chip
        label={value}
        component="a"
        href="#"
        color='secondary'
        variant='outlined'
        size="small"
        clickable
        onClick={handleClick}
      />

        
        <STNSKUDetailModal 
            open={modalOpen}
            onClose={closeModal}
            title={'SKU Details'}
            transferID={props.data.id}
          />
          </>
    );
};

export default LinkCellRenderer;
