import React, { useState,useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import axios from 'axios';
import { IconButton } from '@mui/joy';
import DownloadIcon from '@mui/icons-material/Download';

const PdfGenerator = (props) => {
    const [htmlContent, setHtmlContent] = useState('');
    const [disabled,setButtonDisability] = useState(false)
  
    // Function to handle API call and generate PDF when button is clicked
    const generatePDF = async () => {
      try {
        setButtonDisability(true)
        // Fetch HTML string from API
        const response = await fetch('https://stnapiv2.stcsolutions.shop/api/stocktransfer/GetPDF/' +  props.data.id);
        if (!response.ok) {
          console.error('Error fetching HTML content');
          return;
        }
  
        const data = await response.text();  // Assuming the response is a string
        setHtmlContent(data);
  
        // Ensure the content is available before generating PDF
        if (!data) {
          console.error('No HTML content available for PDF generation');
          return;
        }
  
        // Create a temporary element to hold the HTML content
        const element = document.createElement('div');
        element.innerHTML = data;
  
        // Generate the PDF from the element
        html2pdf()
          .from(element)
          .set({
            filename: 'STN.pdf',
            margin: 8,
            image: { type: 'png', quality: 1 },
            html2canvas: { dpi: 300,scale:2 ,letterRendering: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', autoPaging: true, pageBreak: 'auto'},
          })
          .save();
          setButtonDisability(false)
      } catch (error) {
        console.error('Error during PDF generation:', error);
        setButtonDisability(false)
      }
    };

    return (
        <IconButton color="primary" onClick={generatePDF} disabled={disabled}>
        <DownloadIcon />
            </IconButton>
        // {/* <Button onClick={handleClick} variant="outlined" color="primary" disabled={disabled}>
           
        // </Button> */}
    );
};

export default PdfGenerator;
