import React from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import PageComponent from './PageComponent';
import ListStockTransfer from './ListStockTransfer';
import SKUCreation from './SKUCreation';
import AmazonSKUMapping from './AmazonSKUMapping';
import AmazonSKUMappingList from './AmazonSKUMappingList';
import SKUList from './SKUList';
import Navbar from './Navbar';
import Warehouse from './Warehouse';


function App() {
  return (
    <Router>
      <Navbar/>
   <Routes>
        <Route path="/create-stn" element={<PageComponent />} />
        <Route path="/list" element={<ListStockTransfer />} />
        <Route path="/sku-creation" element={<SKUCreation />} />
        <Route path="/create-mapping" element={<AmazonSKUMapping />} />
        <Route path="/mapping-list" element={<AmazonSKUMappingList />} />
        <Route path="/sku-list" element={<SKUList />} />
        <Route path="/create-warehouse" element={<Warehouse />} />
      </Routes>
      </Router>
  );
  
  
}




export default App;
