import React, { useState } from 'react';
import axios from 'axios';
import { Button, IconButton } from '@mui/joy';
import DownloadIcon from '@mui/icons-material/Download';

const ButtonCellRenderer = (props) => {
    const [buttonText,setButtonText] = useState(props.buttonText)
    const [disabled,setButtonDisability] = useState(false)
    const handleClick = async () => {
        try {
            setButtonText('... Downloading')
            setButtonDisability(true)
            // Replace with your actual API endpoint
            const response = await axios.get(props.url + props.data.id, {
                responseType: 'blob' // Important to handle binary data
            });
            
            // Create a URL for the PDF file
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/' + props.fileExtension }));
            
            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', props.data.shipmentID + '.' + props.fileExtension); // Name the downloaded file
            
            // Append the link to the body and click it to trigger the download
            document.body.appendChild(link);
            link.click();
            
            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            setButtonText(buttonText)
            setButtonDisability(false)
        } catch (error) {
            console.error('Error fetching PDF:', error);
            alert('Failed to download PDF.');
            setButtonText(buttonText)
            setButtonDisability(false)
        }
    };

    return (
        <IconButton color="primary" onClick={handleClick} disabled={disabled}>
        <DownloadIcon />
            </IconButton>
        // {/* <Button onClick={handleClick} variant="outlined" color="primary" disabled={disabled}>
           
        // </Button> */}
    );
};

export default ButtonCellRenderer;
