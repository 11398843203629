import React,{useState,useEffect} from 'react'
import Notify from './components/ToastNotification';
import { Grid, Button } from '@mui/joy';
import TextInput from './components/TextInput';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Dropdown from './components/Dropdown'; 

function Warehouse() {
    const navigate = useNavigate();


    const [gst, setGST] = useState('');
    const [placeCode, setPlaceCode] = useState('');
    const [place, setPlace] = useState('');
    const [gstError, setGSTError] = useState('');
    const [placeCodeError, setPlaceCodeError] = useState('');
    const [placeError, setPlaceError] = useState('');
    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState('');
    const [company, setCompany] = useState('');
    const [companyError, setCompanyError] = useState('');
    const [lstCompany, setlstCompany] = useState([]);
    const [pincode, setPincode] = useState('');
    const [pincodeError, setPincodeError] = useState('');
    const [tallyIdentifier, settallyIdentifier] = useState('');
    const [tallyIdentifierError, settallyIdentifierError] = useState('');

    const handleGSTChange = (event, newValue) => setGST(event?.target.value);
  const handlePlaceCodeChange = (event, newValue) => {
    setPlaceCode(event?.target.value);
  }
  const handlePincodeChange = (event, newValue) => {
    setPincode(event?.target.value);
  }
  const handletallyIdentifierChange = (event, newValue) => {
    settallyIdentifier(event?.target.value);
  }
  const handlePlaceChange = (event, newValue) => {
    setPlace(event?.target.value);
  }
  const handleAddressChange = (event, newValue) => setAddress(event?.target.value);
  const handleCompanyChange =(event, newValue) => setCompany(newValue);

  const getData  = async (apiURL) => {
    try {
        const response = await axios.get(apiURL);
        
        return response.data;
    } catch (error) {
        return error.message;
    } finally {
        
    }
};



  const postData  = async (apiURL,data) => {
    try {
        const response = await axios.post(apiURL,data);
        console.log(response)
        toast.success("SKU created successfully")
        navigate('/mapping-list')
    } catch (error) {
        return toast.error(error.message);
    } finally {
        
    }
};

useEffect(() => {
  const fetchData = async () => {
    const data = await getData("https://stnapiv2.stcsolutions.shop/api/Company");
    setlstCompany(data)
  };

  fetchData();
}, []); 



  function validate(){
    setAddressError('');
    setGSTError('');
    setPlaceCodeError('');
    console.log(placeCode)
    if(gst == null || gst == undefined || gst == ''){
        setAddressError("Please specify asin")
    }
    if(placeCode == null || placeCode == undefined || placeCode == ''){
        setPlaceCodeError("Please specify fnsku")
    }
    if(address == null || address == undefined || address == ''){
      setAddressError("Please specify sku name")
  }
    
    if(gstError.trim().length == 0 && placeCodeError.trim().length == 0 && addressError.trim().length == 0){
        console.log(company)
        const save = async () => {
            const data = await postData("https://stnapiv2.stcsolutions.shop/api/Warehouse",
              {
                gstNumber:gst,
                placeCode: placeCode,
                place: place,
                pincode: pincode,
                address: address,
                companyId: company,
                tallyIdentifier: tallyIdentifier
              }
            );
            console.log(data)
          };
      
          save();
    }
  }

    return (
        <div>
    <Notify />
      <Grid container spacing={2} p={4} sx={{width : '100%'}}>
        
        <Grid item xs={12} mdOffset={4} sm={4}>
          <TextInput
            label="GST Number"
            placeholder="Enter GST"
            errorText={gstError}
            value={gst}
            onChange={handleGSTChange}
          />

        </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>
          <TextInput
            label="Place Code (This is for System)"
            placeholder="Enter Place code"
            errorText={placeCodeError}
            value={placeCode}
            onChange={handlePlaceCodeChange}
          />

        </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>
          <TextInput
            label="Place (This is for Eway Bill)"
            placeholder="Enter Place"
            errorText={placeError}
            value={place}
            onChange={handlePlaceChange}
          />

        </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>
          <TextInput
            label="Address"
            placeholder="Enter Address"
            errorText={addressError}
            value={address}
            onChange={handleAddressChange}
          />

        </Grid>
        <Grid  item xs={12} mdOffset={4} sm={4}>
        <TextInput
            label="Pincode"
            type='number'
            placeholder="Enter Pincode"
            errorText={pincodeError}
            value={pincode}
            onChange={handlePincodeChange}
          />

        </Grid>
        <Grid  item xs={12} mdOffset={4} sm={4}>
        <TextInput
            label="Tally Identifier"
            placeholder="Enter Tally Identifier"
            errorText={tallyIdentifierError}
            value={tallyIdentifier}
            onChange={handletallyIdentifierChange}
          />

        </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>

        <Dropdown
            label="Company"
            value={company}
            options={lstCompany}
            keyIdentifier="id"
            valueIdentifier="id"
            displayTextIdentifier="placeCode"
            errorText={companyError}
            onChange={handleCompanyChange}
          />
          </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>
        <Button variant="outlined" color="primary" onClick={validate}>
            Create Warehouse
          </Button>

        </Grid>
      </Grid>
    </div>
    );
}

export default Warehouse;