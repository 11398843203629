import React from 'react';
import { FormControl, FormLabel, Input } from '@mui/joy';

const DatePicker = ({ value, onChange,dateLabel }) => {
  return (
    <FormControl>
    <FormLabel>{dateLabel}</FormLabel>
    <Input
      label="Today's Date"
      type="date"
      value={value}
      onChange={onChange}
      fullWidth
      InputLabelProps={{ shrink: true }}
    />
    </FormControl>
  );
};

export default DatePicker;
