import React, { useState } from 'react';
import axios from 'axios';
import { Button, DialogActions, IconButton, Modal,ModalDialog,Divider, DialogContent,DialogTitle} from '@mui/joy';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { toast } from 'react-toastify';

const DeleteButtonCellRenderer = (props) => {
    const [open, setOpen] = useState(false);
    const handleDelete = async () => {
        try {
            console.log(props.gridRef)
            const response = await axios.delete(props.url + props.data.id);
            if(response.data == true){
              toast.success("STN " + props.data.shipmentID + " deleted successfully",{
                "position" : "top-center"
              })
            } 
            
            setOpen(false)
        } catch (error) {
            console.error('Failed to Delete STN', error);
            toast.error('Failed to Delete STN');
        }
    };

    return (
        <>
        <IconButton color="primary" onClick={ () => setOpen(true)} >
            <DeleteIcon />
        </IconButton>

        <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            Are you sure you want to delete?
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={handleDelete }>
              Delete
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      </>
        
    );
};

export default DeleteButtonCellRenderer;
