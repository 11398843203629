import React,{useState} from 'react'
import Notify from './components/ToastNotification';
import { Grid, Button } from '@mui/joy';
import TextInput from './components/TextInput';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function SKUCreation() {
    const navigate = useNavigate();


    const [skuName, setSKUName] = useState('');
    const [mrp, setMRP] = useState('');
    const [skuNameError, setSKUNameError] = useState('');
    const [mrpError, setMRPError] = useState('');

    const handleSKUNameChange = (event, newValue) => setSKUName(event?.target.value);
  const handleMRPChange = (event, newValue) => {
    setMRP(event?.target.value);
  }


  const postData  = async (apiURL,data) => {
    try {
      
        const response = await axios.post(apiURL,data);
        if(response.data){
          if(response.data == "SKU Already Exists"){
            toast.error("SKU Already Exists")
            setSKUNameError("SKU Already Exists")
          }
          else{
            toast.success("SKU created successfully")
            navigate('/sku-list')
          }
        }
        
        
    } catch (error) {
        return toast.error(error.message);
    } finally {
        
    }
};

  function validate(){
    setSKUNameError('');
    setMRPError('');
    console.log(mrp)
    let skuError = "";
    let mrperror="";
    if(skuName == null || skuName == undefined || skuName == ''){
        skuError = "Please specify sku name";
    }
    if(mrp == null || mrp == undefined || mrp == '' || mrp <= 0){
        mrperror= "Invalid mrp";
    }
    setSKUNameError(skuError)
    setMRPError(mrperror);
    if(skuError.length == 0 && mrperror.length == 0){
        const save = async () => {
            const data = await postData("https://stnapiv2.stcsolutions.shop/api/SKU",{name : skuName,mrp : mrp,image : null});
            console.log(data)
          };
      
          save();
    }
  }

    return (
        <div>
    <Notify />
      <Grid container spacing={2} p={4} sx={{width : '100%'}}>
        
        <Grid item xs={12} mdOffset={4} sm={4}>
          <TextInput
            label="SKU Name"
            placeholder="Enter SKU Name"
            errorText={skuNameError}
            value={skuName}
            onChange={handleSKUNameChange}
          />

        </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>
          <TextInput
            label="MRP"
            type="number"
            placeholder="Enter SKU Name"
            errorText={mrpError}
            value={mrp}
            onChange={handleMRPChange}
          />

        </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>
        <Button variant="outlined" color="primary" onClick={validate} >
            Create SKU
          </Button>

        </Grid>
      </Grid>
    </div>
    );
}

export default SKUCreation;