import React, { useState,useEffect } from 'react';
import { Modal, ModalDialog, ModalClose, Button,Grid,Box } from '@mui/joy';
import DataGrid from './DataGrid';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const STNSKUDetailModal = ({ open, onClose, title,transferID}) => {
    const columnDefs = [
        { headerName: "ID", field: "id", hide : true },
        { headerName: "ASIN /FSN", field: "amazonSKUMapping.asin",width : 130 },
        { headerName: "FNSKU / Listing ID", field: "amazonSKUMapping.fnsku",width : 130 },
        { headerName: "SKU", field: "amazonSKUMapping.skuName" ,width : 350},
        { headerName: "QTY", field: "totalQuantity",width : 80 },
        { headerName: "MRP", field: "mrp",width : 80 },
        { headerName: "Amount", field: "totalAmount" }
      ];

      const [rowData, setRowData] = useState([]);
      const getData  = async (apiURL) => {
        try {
            const response = await axios.get(apiURL);
            
            return response.data;
        } catch (error) {
            return error.message;
        } finally {
            
        }
    };

    useEffect(() => {
        const fetchData = async () => {
          const data = await getData("https://stnapiv2.stcsolutions.shop/api/stockmovement/" + transferID);
          setRowData(data);
        };
    
        fetchData();
      }, []); 

  return (

        <Modal open={open} onClose={onClose}>
    <ModalDialog size='lg'>
        <ModalClose />
        <h2>{title}</h2>
        
        
        <div style={{width : '1000px'}}>
          <DataGrid
            rowData={rowData}
            columnDefs={columnDefs}
            height={'400px'}
          />
          </div>
       
      </ModalDialog>
      
    </Modal>


  );
};

export default STNSKUDetailModal;