import React from 'react';
import { FormLabel, Input } from '@mui/joy';

const TextInput = ({ placeholder = 'Enter text', value, onChange, label, type = 'text', fullWidth = true, errorText, ...props }) => {
  return (
    <div style={{ marginBottom: '16px' }}>
      {label && <FormLabel style={{ marginBottom: '4px', display: 'block' }}>{label}</FormLabel>}
      <Input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        fullWidth={fullWidth}
        {...props}
      />
      <FormLabel sx={{color:'orangered'}}>{errorText}</FormLabel>
    </div>
  );
};

export default TextInput;